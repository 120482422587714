import React from 'react';
import VipService from './vipService';

const vipServices = [
  {
    service: '专享福利',
    iconName: 'VipZhongShen',
    hoverContent: `专属福利游戏库<br />
    不同档位体验金<br />
    高价值特权礼包`
  },
  {
    service: '特别活动',
    iconName: 'VipZhuanShu',
    hoverContent: `不定期专属活动`
  },
  {
    service: '专属服务',
    iconName: 'VipQuDao',
    hoverContent: `专属QQ、微信渠道，
    随时随地快速便捷联系我们`,
  },
  {
    service: '专享礼包',
    iconName: 'VipLiBao',
    hoverContent: `专属游戏礼包`
  },
  // {
  //   service: '实物关怀福利',
  //   iconName: 'VipGuanHuai',
  //   hoverContent: '生日礼品（游戏周边、360产品等）'
  // },
  // {
  //   service: '一对一服务',
  //   iconName: 'VipYiDuiYi',
  //   hoverContent: `专人高效服务`
  // },
  // {
  //   service: '账号安全保护',
  //   iconName: 'VipAnQuan',
  //   hoverContent: `保障账号安全<br />
  //   账号异常监控`
  // },
  // {
  //   service: '贵宾专线',
  //   iconName: 'VipZhuanXian',
  //   hoverContent: `400-669-3600 转4<br />
  //   工作时间：10:00一19:00`
  // },
]

const Vip = () => {
    const style = {
      boxSizing: 'border-box',
      paddingRight: '30px',
    }
    return (
      <section style={style}>
         <style jsx>{`
             .tip {
               background: #FFEEE3;
               padding: 18px 34px 18px 26px;
               line-height: 22px;
               box-sizing: border-box;
               border-left: 5px solid #F88155
             }
             .vip-content {
              display: flex;
              width: 100%;
              justify-content: space-between;
              flex-flow:row wrap;
              margin-top: 16px;
             }
             .single {
               margin-bottom: 16px;
               position: relative;
             }
             .single:hover .showhidden{
              opacity: 1;
             }
             .single:hover .showinclude {
              top: 52px;
             }
             .showinclude {
               width: 192px;
               position: absolute;
               top: -50px;
               left: 50%;
               transform: translateX(-50%);
               transition: top 0.3s;
             }
             .showhidden {
               background: #FFEEE3;
               overflow: hidden;
               width: 220px;
               height: 220px;
               position: absolute;
               left: 0;
               top: 0;
               transition: opacity 0.4s;
               opacity: 0;
               color: #333;
              //  padding: 40px 10px 0;
             }
             .showhidden-title {
               text-align: center;
               color: #333;
               margin-bottom: 14px;
               font-size: 16px;
               font-weight: bold;
             }
             .showhidden-content {
               width: 100%;
               position: absolute;
               top: 50%;
               transform: translateY(-50%);
               display: flex;
               align-items: center;
               justify-content: center;
              //  text-align: center;
             }
             .showhidden-content-father {
               height: 110px;
               width: 100%;
               position: relative;
             }
            //  .safety-tip {
            //    height: 165px;
            //    width: 165px;
            //    background: #FFEEE3;
            //    border: 1px dashed #333;
            //    font-size: 12px;
            //    box-sizing: border-box;
            //    margin-left: 348px; 
            //    margin-top: 38px;              
            //    padding: 30px 5px 0;
            //  }
            //  .safety-tip h6 {
            //   text-align: center;
            //   font-weight: 600;
            //   margin-bottom: 20px;
            //  }
            //  .tip-content {
            //   text-align: justify;
            //  }
         `}
         </style>
         <div className="tip">
          360大客户经理是您的专属游戏管家，可以为您提供更好的游戏体验和解决问题的快捷渠道，以及额外的专属福利，并为您的360账号保驾护航。当您的账号达到我们的服务标准后，我们就会主动联系您为您服务。
         </div>
         <ul className="vip-content">
           {vipServices.map(item => {
             return (<li className="single" key={item.iconName}>
               <VipService iconName={item.iconName} service={item.service} hoverContent={item.hoverContent} />
               <div className="showhidden">
                  <div className="showinclude">
                    <h6 className="showhidden-title">{item.service}</h6>
                    <div className="showhidden-content-father">
                      <p className="showhidden-content"  dangerouslySetInnerHTML={{__html: item.hoverContent}}></p>
                    </div>
                  </div>
               </div>
              </li>)
           })}
         </ul>
      </section>
    )
}
                          
export default Vip