import React,{ useEffect, useState } from 'react';
import { fetchData } from 'helpers';
import FormTypes from './formTypes';
import form404Url from 'assets/images/form404.png';
import { formDialog } from 'assets/js/dialog';
import { connect } from 'react-redux';
import routes from 'routes';
import {
  Form,
//   message,
//   DatePicker,
//   Input,
//   Tooltip,
//   Icon,
//   Cascader,
//   Select,
//   Row,
//   Col,
//   Checkbox,
  Button,
//   AutoComplete,
} from 'antd';

function createNewFormItem(opts = {}) {
    const { data, originalObj, label, code, type, num } = opts;
    const originalCopy = Object.assign({}, originalObj);
    originalCopy.label = label;
    originalCopy.code = code;
    const index = data.fields.findIndex(item => item.type === type);
    if (type === 'address_field') {
        originalCopy.type = 'text_field'
    }
    data.fields.splice(index+num, 0, originalCopy);
}

function diyValidate(item, presence) {
    // let presence = item.validations && item.validations.presence;
    let options = {
        validateTrigger: 'onBlur',
        rules: [
            {
                required: item.validations && presence === '1',
                message: `请输入您的${item.label}`,
            },
        ],
    }
    // switch(item.type) {

    // }
    // 懒得抽离模块了 就直接if吧 麻烦。
    if (item.type === 'mobile_field') {
        options.rules.push({
            pattern: /1[^01]\d{9}/,
            message: '请输入正确的手机号',
        })
    }
    if (item.type === 'idcards_field') {
        options.rules.push({
            pattern: /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/,
            message: '请输入正确的身份证格式',
        })
    }
    if (item.type === 'email_field') {
        let reg = /^\w+((.\w+)|(-\w+))@[A-Za-z0-9]+((.|-)[A-Za-z0-9]+).[A-Za-z0-9]+$/
        options.rules.push({
            pattern: reg,
            message: '请输入正确的邮箱格式',
        })
    }
    return options
}

const CustomizedForm = (props) => {
    const { uploadList, formList, history, dispatch } = props;
    const [ formData, setFormData ] = useState('');
    const [ submitLoading, setSubmitLoading ] = useState(false);
    const { getFieldDecorator } = props.form;
    const params = new URLSearchParams(props.location.search);
    const fkey = params.get('fkey');
    const origin_tasknum = params.get('num');
    // const { submitFormData } = props;
    useEffect(() => {
        
        fetchData('getFormDetail', { fkey, }).then((result) => {
            if (result && Object.keys(result.data).length>0) {
                const data = result.data;

                dispatch({
                    type:'SET_ACCOUNT_ID',
                    accountId:data.question_cate_id
                })

                // dispatch({
                //     type: 'RESET_ROUTE_NAME',
                //     routeName: data.name,
                // })
                const address = data.fields.find(item => item.type === 'address_field');
                const bank = data.fields.find(item => item.type === 'bank_field')
                if(address && address.street_style === '1') {
                    dispatch({
                        type: 'STORE_ADDRESS_CODE',
                        addressCode: address.code,
                    })
                    createNewFormItem({
                        data,
                        label: '详细地址',
                        code: 'addressDetail',
                        originalObj: address,
                        type: 'address_field',
                        num: 1,
                    });
                }
                if (bank) {
                    bank.label = '卡号';
                    dispatch({
                        type: 'STORE_BANK_CODE',
                        bankCode: bank.code,
                    })
                    createNewFormItem({
                        data,
                        label: '银行',
                        code: 'bankLocated',
                        originalObj: bank,
                        type: 'bank_field',
                        num: 1,
                    });
                    if (bank.open_style === '1') {
                        createNewFormItem({
                            data,
                            label: '归属地',
                            code: 'bankWhereBelong',
                            originalObj: bank,
                            type: 'bank_field',
                            num: 2,
                        });
                        createNewFormItem({
                            data,
                            label: '开户行',
                            code: 'openBank',
                            originalObj: bank,
                            type: 'bank_field',
                            num: 3,
                        });
                    }
                }
                setFormData(data);
            } else {
                setFormData({});
            }
            
        }).catch((err) => {
            throw new Error(err);
        });
    },[dispatch,fkey]);
    let getBgTheme = new Map([
        ['foison', '#FFFAED'],
        ['fresh', '#F4FFF9']
    ])
    let theme = getBgTheme.get(formData.bg_theme);
    const styles = {
        backgroundImage:'url('+formData.bg+')',
        // backgroundSize: '100% 100%',
        backgroundRepeat: 'no-repeat',
        backgroundColor: theme ? theme : 'white',
        width: '100%',
        minHeight: '710px',
        padding: '0 30px 30px',
        overflow: 'hidden',
        display: formData && Object.keys(formData).length>0 ? 'block' : 'none',
    }
    const style2 = {
        // display: Object.keys(formData).length ? 'none' : 'block',
        width: '290px',
        height: '261px',
        margin: '100px auto',
    }
    const formItemLayout = {
        labelCol: {
            sm: 10,
        },
        wrapperCol: {
            sm: 12,
        },
    };
    const submitData = (e) => {
        e.preventDefault();
        let gameHallFormOptions = JSON.parse(window.sessionStorage.getItem('gameHallFormOptions'));
        props.form.validateFields((err, values) => {
            if (!err) {
                setSubmitLoading(true);
                const params = new URLSearchParams(props.location.search);
                const fkey = params.get('fkey');
                const options ={ fkey, ...formList, ...uploadList, ...gameHallFormOptions };
                if (props.bankCode) {
                    options[props.bankCode] = {
                        number: options[props.bankCode],
                        name: options.bankLocated,
                        ownership: options.bankWhereBelong,
                        open: options.openBank,
                    }
                }
                if (props.addressCode && options.addressDetail) {
                    options[props.addressCode] = options[props.addressCode]+'/'+ options.addressDetail;
                }
                if (origin_tasknum) {
                    options.origin_tasknum = origin_tasknum;
                }
                let qucParams = ['qid', 'timestamp', 'src', 'ukey', 'skey', 'pkey', 'gkey'];
                qucParams.forEach(item => {
                    let param = params.get(item);
                    if (param) {
                        options[item] = param;
                    }
                })
                fetchData('submitForm', options).then((result) => {
                    if (result && result.errno === 0) {
                        dispatch({
                            type: 'RESET_FORM_LIST',
                        })
                        setTimeout(() => {
                            formDialog.success('提交成功，1-3个工作日内，请您留意短信、邮件，以及客服中心"我的问题', () => {
                                history.push(`/myquiz`)
                            });
                            setSubmitLoading(false);              
                        }, 3000);
                    } else {
                        // 防止报错 不能在组建销毁后还在设置hook属性
                        setSubmitLoading(false);
                    }
                }).catch((err) => {
                    Promise.reject(err);
                    setSubmitLoading(false);
                });
            }
        })
    }
    return (
    <React.Fragment>
        <section style={styles}>
            <style>
                {`
                    .ant-form-explain, .ant-form label {
                        // font-size: 12px;
                    }
                    .ant-input {
                        // font-size: 12px;
                        height: 34px;
                        width: 230px;
                        border-radius: 2px;
                    }
                    .submit-button .ant-btn-primary {
                        background: #D0021B;
                        border-color: #D0021B;
                        color: white;
                        padding: 0 34px;
                    }
                    .submit-button .ant-btn {
                        border-radius: 2px
                    }
                `}
            </style>
            <style jsx>{`
                .title {
                    font-size: 18px;
                    font-weight: 600;
                    text-align: center;
                    margin-top: 124px;
                    margin-bottom: 20px;
                }
                .form {
                    margin-top: 20px;
                }
                .submit-button {
                    margin-top: 36px;
                    text-align: center;
                    display: ${formData.fields ? 'block' : 'none'};
                }
                .explain-text {
                    text-align: center;
                }
                // .submit-button button {
                //     display: block;
                //     width: 104px;
                //     height: 28px;
                //     background: #D0021B;
                //     color: white;
                //     margin: 0 auto;
                        // font-size: 12px;
                //     border-radius: 2px;
                // }
            `}
            </style>
            <div className="title">{formData.name}</div>
            <div className="explain-text">
            {formData.description}
            </div>
            <section className="form">
                <Form {...formItemLayout} onSubmit={submitData}>
                    {formData.fields && formData.fields.map((item, index) => {
                        let presence = item.validations && item.validations.presence;
                        if (item.type === 'bank_field' && typeof presence !== 'string') {
                            switch(item.code) {
                                case 'bankLocated':
                                    presence = presence.name;
                                break;
                                case 'bankWhereBelong':
                                    presence = presence.ownership;
                                break;
                                case 'openBank':
                                    presence = presence.open;
                                break;
                                default:
                                    presence = presence.number;
                                break;
                            }
                        }
                        // let options = {
                        //     rules: [
                        //         {
                        //             required: item.validations && presence === '1',
                        //             message: `请输入您的${item.label}`,
                        //         },
                        //     ],
                        // }
                        return (
                            <Form.Item label={item.label} key={item.code}>
                                {
                                    getFieldDecorator(item.code, diyValidate(item, presence))(<FormTypes {...item} form={props.form} dispatch={props.dispatch} />)
                                }
                            </Form.Item>  
                            
                        )
                    })}
                    {/* <Form.Item> */}
                        <section className="submit-button">
                            <Button type="primary" htmlType="submit" loading={submitLoading}>提交</Button>
                        </section>
                        
                    {/* </Form.Item> */}
                </Form>
            </section>
        </section>
        {formData && !Object.keys(formData).length && (<section style={style2}>
            <style>{`
                .form404img {
                    width: 100%;
                    height: 100%;
                }
            `}</style>
            <img className="form404img" src={form404Url} alt="" />
        </section>)}
     </React.Fragment>
   )
}

// const filesType = ['.jpeg','.jpg','.png','.pdf','.bmp']

const ChangePhone = Form.create({
    name: 'global_state',
    onValuesChange(props, values) {
        console.log(123);
        const code = Object.keys(values)[0];
        props.dispatch({
            type: 'SET_TEXT',
            code,
            value: values[code],
        })
    },
  })(CustomizedForm);


function mapStateToProps(state) {
    return {
        formList: state.formReducer.formList,
        uploadList: state.formReducer.uploadList,
        bankCode: state.formReducer.bankCode,
        addressCode: state.formReducer.addressCode,
    }
}
export default connect(mapStateToProps)(ChangePhone)