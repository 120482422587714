import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router';
import BaseIcon from 'assets/svgIcon/baseIcon';
import { fetchData } from 'helpers';


const Report = (props) => {
    const { title, time, top} = props;
    const style = {
        display: 'flex',
        alignItems: 'center',
        // justifyContent: 'space-between',
        position: 'relative',
        // paddingLeft: '10px',
        marginTop: '12px',
    }
    return (
      <section style={style}>
         <style jsx>{`
             .report-content {
                 color: ${top === '1' ? '#D0021B' : '#333'};
                 width: 300px;
                 overflow: hidden;
                 padding-left: 8px;
                 white-space: nowrap;
                 text-overflow: ellipsis;
                 flex: 1;
                 line-height: 1.4;
             }
             .circle {
                 width: 4px;
                 height: 4px;
                 border-radius: 50%;
                 background: #d9d9d9;
                //  position: absolute;
                //  top: 50%;
                //  left: 0;
                //  transform: translateY(-50%);
             }
             .report-date {
                 font-size: 12px;
             }
         `}
         </style>
         <p className="circle"></p>
         <p className="report-content add-pointer">
            {title}
         </p>
         <p className="report-date">
             {time}
         </p>
      </section>
    )
}

const toNoticeDetail =(history, id) => {
    history.push(`/announcement?noticeId=${id}`)
}

// 公告板块
const Announcement = (props) => {
    const [noticeList, setNoticeList] = useState([]);
    useEffect(() => {
        fetchData('getNoticeList').then((res) => {
            setNoticeList(res.data);
        }).catch((err) => {
            
        });
    },[])
    return (
      <section>
         <style jsx>{`
            .announcement {
                width: 472px;
                height: 208px;
                background: white;
                box-shadow: 0px 0px 3px 2px rgba(0,0,0,0.14);
            }
            .title-fence {
                height: 40px;
                background: linear-gradient(#fff, #f0f0f0);
                display: flex;
                align-items: center;
                padding-left: 10px;
                font-size: 14px;
            }
            .report-list {
                height: 168px;
                padding: 0 10px 10px;
                box-sizing: border-box;
                overflow-y: auto;
            }
            // .report-list::-webkit-scrollbar {
            //     background: red;
            // }
         `}
         </style>
         <section className="announcement">
             <div className="title-fence">
                <BaseIcon iconName="Announcement" width={16} height={16} />
                <span style={{fontWeight: 'bold',marginLeft:'6px',fontSize: '16px'}}>公告</span>
             </div>
             <ul className="report-list">
                 {noticeList && noticeList.map((item) => {
                     return (<li key={item.id} onClick={() => toNoticeDetail(props.history,item.id)}><Report {...item} /></li>)
                 })}
             </ul>
         </section>
      </section>
    )
}
                          
export default withRouter(Announcement)