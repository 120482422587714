import React, {useEffect} from 'react';
import { message } from 'antd';
import { connect } from 'react-redux';
import BaseIcon from 'assets/svgIcon/baseIcon';
import quc from './quc';


// .then((e) => {
//    window.QHPass.init({src: 'pcw_i360',signIn: { types: ['quick','normal']}});
// })

function login(dispatch) {
   window.QHPass.signIn(function(userInfo) {
      dispatch({
         type: 'SET_LOGIN_STATUS',
         isLogin: userInfo.nickname ? userInfo.nickname : userInfo.username,
      })
      message.success('登录成功');
      window.location.reload();
   });
}

function register(dispatch) {
   window.QHPass.signUp((userInfo) => {
      dispatch({
         type: 'SET_LOGIN_STATUS',
         isLogin: userInfo.nickname ? userInfo.nickname : userInfo.username,
      })
      message.success('注册成功')
   })
}

function logout(dispatch) {
   window.QHPass.signOut(() => {
      dispatch({
         type: 'SET_LOGIN_STATUS',
         isLogin: ''
      })
      message.success('退出成功');
      window.location.reload();
   })
}

const LoginBar = (props) => {
   const { isLogin, dispatch } = props;
   const style = {height: '23px', width: '1200px', position: 'relative',margin: '0 auto'};
   useEffect(() => {
      quc.then((e) => {
         let QHPass = window.QHPass;
         QHPass.init({src: 'pcw_gamecs',signIn: { types: ['mobile','normal']}});
         QHPass.getUserInfo((userInfo) => {
            dispatch({
               type: 'SET_LOGIN_STATUS',
               isLogin: userInfo.nickname ? userInfo.nickname : userInfo.username,
            })
         })
      })
   },[dispatch])
   return (
      <section style={style}>
         <style jsx>{`
            .buttons {
               display: flex;
               height: 100%;
               align-items: center;
            //   margin-left: 1098px;
               position: absolute;
               right: 0;
            }
            .vertical {
               margin-left: 14px;
               margin-right: 14px;
            }
            .logout {
               display: flex;
               align-items: center;
               margin-left: 14px;
            }
            .exit {
               color: #666;
               margin-left: 2px;
            }
         `}
         </style>
         {
            !isLogin ? 
            (<section className="buttons">
               <button className="login" onClick={() => login(props.dispatch)}>登录</button>
               <span className="vertical">|</span>
               <button className="register" onClick={() => register(props.dispatch)}>注册</button>
            </section>) : 
            (<section className="buttons">
               <p>{isLogin}</p>
               <div className="logout add-pointer" onClick={() => logout(props.dispatch)}>
                  <BaseIcon iconName="Logout" width={12} height={12} />
                  <p className="exit">退出</p>
               </div>
            </section>)
         }
      </section>
   )
}

function mapStateToProps(state) {
   return {
       isLogin: state.showReducer.isLogin
   }
}

export default connect(mapStateToProps)(LoginBar)