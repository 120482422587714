import React, {useState} from 'react';
import {withRouter} from 'react-router';
import imgUrl from 'assets/images/serviceAvatar.png';
import imgUrl2 from 'assets/images/consultFont.png';
import {connect} from 'react-redux';
import Draggable from 'react-draggable';

const toOnlineConsultation = (history) => {
  // console.log('click');
  // return;
  window.open('https://zhike.help.360.cn/?rid=c1ae0d32-bcc1-4855-b937-886783ce6423');
  return;
  window.location.href = 'https://zhike.help.360.cn/?rid=c1ae0d32-bcc1-4855-b937-886783ce6423'
}

const CustomerService = (props) => {
  const {location} = props.history;
  let pathname = location.pathname;
  let search = location.search;
  const style = {
    position: 'absolute',
    cursor: 'pointer',
    top: '300px',
    right: '20px',
    zIndex: '9999',
    // display: pathname === '/onlineconsultation' && search.includes('fkey') ? 'none' : 'block',
  }
  const [isDrag, setIsDrag] = useState(false);

  return (
    <Draggable
      onStart={() => {
        setIsDrag(false);
      }}
      onDrag={() => {
        setIsDrag(true);
      }}
      onStop={() => {
        setTimeout(()=>{
          setIsDrag(false);
        },200);
      }}>
      <section style={style} onClick={() => isDrag ? void (0) : toOnlineConsultation(props.history)}
      >
        <style jsx>{`
             .top {
                height: 38px;
                font-size: 14px;
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: space-around;
                background: linear-gradient(#DF343E, #B81C25);
             }
             .top h6 {
                font-size: 14px;
             }
             .title {
                 color: white;
             }
             .avatar {
                //  padding: 5px 14px;
                width: 80px;
                height: 80px;
             }
             .bottom {
                 height: 28px;
                 width: 78px;
             }
             .avatar img, .bottom img {
                width: 100%;
                height: 100%;
            }
         `}
        </style>
        {/* <div className="top">
            <h6 className="title">在线客服</h6>
         </div> */}
        <div className="avatar">
          <img src={imgUrl} alt='' onMouseDown={(event) => {
            event.preventDefault()
          }}
               onMouseMove={(event) => {
                 event.preventDefault()
               }} onMouseUp={(event) => {
            event.preventDefault()
          }}/>
        </div>
        <div className="bottom add-pointer">
          <img src={imgUrl2} alt='' onMouseDown={(event) => {
            event.preventDefault()
          }}
               onMouseMove={(event) => {
                 event.preventDefault()
               }} onMouseUp={(event) => {
            event.preventDefault()
          }}/>
        </div>
      </section>
    </Draggable>

  )
}

function mapStateToProps(state) {
  return {
    isShowOnlineService: state.showReducer.isShowOnlineService
  };
}

export default connect(mapStateToProps)(withRouter(CustomerService));