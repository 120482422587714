import React, { useEffect } from 'react'
import Announcement from './announcement';
import HotQuiz from './hotQuiz';
import Banner from './banner';
import AllKindsConsult from './allKindsConsult';

let toolSearch = (function() {
  // let urlSearch = window.location.search;
  let params = new URLSearchParams(window.location.search);
  let searchs = ['pname', 'pkey', 'gkey', 'gname', 'skey', 'sname', 'account'];
  let url = '';
  searchs.forEach((item) => {
      let res = params.get(item);
      if (res) {
          url += '&' + item + '=' + res
      }
  })
  // if (url && !urlSearch.includes('?')) url.replace('&', '?')
  return url;
})()

let isInitPush = true;

const CenterPages = (props) => {
  if (isInitPush) {
    let { push } = props.history;
    props.history.push = (values) => {
      let { pathname } = window.location;
      let ma = values.match(/\/(.+)\??/);
      if (!ma && pathname === '/') return;
      if (ma && pathname !== '/' && ma === pathname.split('/')[1]) {
        return;
      }
      let url = values + toolSearch;
      if (toolSearch && !url.includes('?')) {
        url = url.replace('&', '?');
      }
      push(url);
    }
    isInitPush = false;
  }
    
    
    return (
      <section>
         <style jsx>{`
             .center-container {
               width: 956px;
             }
             .banner {
               margin-top: 10px;
             }
             .hot-quiz {
               margin-left: 10px;
             }
         `}
         </style>
         <section className="center-container">
            <div style={{display: 'flex',justifyContent: 'space-between'}}>
              <Announcement />
              <HotQuiz />
            </div>
            <div className="consults">
              <AllKindsConsult history={props.history} />
            </div>
         </section>
      </section>
    )
}
                          
export default CenterPages