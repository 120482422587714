import React from 'react'
import Logo from '../assets/svgIcon/360logofooter';

const maps = new Map([
    ['/myquiz/quizdetail', '/quizdetail'],
    ['/onlineconsultation', '/gamechoose'],
    ['/onlineconsultationim', '/'],
    ['/accountproblems', '/quikform'],
    ['/accountproblems/accountdetail', '/gamechoose'],
    ['/accountproblems/accountcontent', '/nofkeycontent']
])

const switchQuery = (query, before, after) => {
    return query.replace(before, after);
}

const switchToPhone = () => {
    let location = window.location;
    let name = maps.get(location.pathname);
    let query = location.search;
    if (name) {
        if (name === '/quikform') {
            query = switchQuery(query, 'accountid', 'id')
        }
        if (name === '/nofkeycontent') {
            query = switchQuery(query, 'fid', 'id')
        }
        window.location.href = `//h5.gm.game.360.cn${name}${query}`;
        return;
    }
    window.location.href = `//h5.gm.game.360.cn${location.pathname}${query}`
}

if(/Android|webOS|iPhone|iPod|iPad/i.test(navigator.userAgent)) {
    switchToPhone();
}
const Footer = () => {
    return (
      <section>
         <style jsx>{`
             .footer {
                width: 100%;
                height: 98px;
                background: #e8e8e8;
                display: flex;
                font-size: 12px;
                min-width: 1200px;
            }
            .footer-content {
                position: relative;
                padding-top:22px;
                min-width: 1200px;
                margin: 0 auto;
            }
            .logo {
                position: absolute;
                left: 0;
                top: 53%;
                transform: translateY(-50%);
            }
            .negotiation a, .negotiation button {
                white-space: nowrap !important;
                margin-left: 10px;
                // cursor: pointer;
            }
            .negotiation {
                // margin-left: 450px;
                position: absolute;
                right: 0;
            }
            .top {
                // width: 600px;
                display: flex;
                flex-direction: row-reverse;
                justify-content: space-between;
            }
            .center {
                // width: 600px;
                display: flex;
                flex-direction: row-reverse;
                justify-content: space-between;
            }
            .bottom {
                display: flex;
                flex-direction: row-reverse;
            }
            .switchToPhone {
                color: #999090;
            }
         `}
         </style>
         <section className="footer">
            <div className="footer-content">
                <div className="logo"><Logo width={160} height={40} isShowSC={false} fill="white"/></div>
                <div className="negotiation">
                    <div className="top">
                        <a href="http://wan.360.cn/protocol.html" target="_blank" rel="noopener noreferrer">360游戏服务及许可协议</a>
                        <a href="mailto:game@360.cn">游戏商务合作</a>
                        <a href="http://mis.h5.u.360.cn/wcn/index.htm" target="_blank" rel="noopener noreferrer">游戏组件未成年人家长监护工程</a>
                        <a href="http://p0.qhimg.com/t0169dceb0c0d596fcd.jpg" target="_blank" rel="noopener noreferrer">北京世界星辉科技有限责任公司</a>
                    </div>
                    <div className="center">
                        <a href="http://p0.qhimg.com/t014426b49a8ab2477c.jpg" target="_blank" rel="noopener noreferrer">京ICP证140771号</a>
                        <button>京ICP备11014623号-1</button>
                        <a href="http://p8.qhimg.com/t01d6f46129b45e3f03.jpg" target="_blank" rel="noopener noreferrer">京网文[2018]9597-828号</a>
                        <a href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=11000002000006" target="_blank" rel="noopener noreferrer">京公网安备 11000002000006号</a>
                    </div>
                    <div className="bottom">
                        <button>Copyright<span role="img" aria-label="">©️</span>2005-2019 360.CN All Rights Reserved 360网页游戏中心</button>
                        {/* <button className="switchToPhone" onClick={switchToPhone}>切换至手机版</button> */}
                    </div>
                </div>
            </div>
        </section>
      </section>
    )
}

export default Footer