import React, { useEffect, useState } from 'react';
import { Table } from 'antd';
import EvaluateModal from './evaluateModal';
import { Link} from 'react-router-dom';
import tableEmptySrc from 'assets/images/tableEmpty.png';
import { domChange } from 'assets/js/utils';
import { fetchData } from 'helpers';
// import QuizDetail from './quizDetail';

const SetAction = (text,props) => {
  const style = {
    display: 'flex',
    flexWrap: 'wrap',
  }
  // let quizTitle=encodeURIComponent(props.title);
  const linkUrl = `/myquiz/quizdetail?num=${props.num}&quizTitle=${props.title}`;
  return (
    <section style={style}>
      <div className="add-pointer" style={{marginRight: '8px'}}><Link to={linkUrl}>查看</Link></div>
      <div className="add-pointer control-eva"><EvaluateModal num={props.num} isAssess={props.is_assess}   /></div>
      {/* <div className="add-pointer" style={{color: '#999'}}>投诉</div> */}
    </section>
  )
}


const columns = [
  {
    title: '问题编号',
    dataIndex: 'num',
    key: 'num',
    width: 200,
    className: 'changeTdStyle',
  },
  {
    title: '问题类型',
    dataIndex: 'category',
    key: 'category',
    width: 239,
    className: 'changeTdStyle',
  },
  {
    title: '问题标题',
    dataIndex: 'title',
    key: 'title',
    width: 239,
    className: 'changeTdStyle',
  },
  {
    title: '游戏名称',
    dataIndex: 'game',
    key: 'game',
    width: 239,
    className: 'changeTdStyle',
    render:(n,row) => {
      return `${row.platform}${row.game}`
    }
  },
  {
    title: '评价',
    dataIndex: 'assess_level',
    key: 'assess_level',
    width: 239,
    className: 'changeTdStyle',
  },
  {
    title: '创建时间',
    dataIndex: 'create_time',
    key: 'create_time',
    className: 'changeTdStyle',
    width: 114,
  },
  {
    title: '当前状态',
    dataIndex: 'status',
    key: 'status',
    width: 82,
    className: 'changeTdStyle status-style',
    render: (text) => {
      return (
        <React.Fragment>
          <i style={{color: text === '新回复' ? 'red' : '#333'}}>{text}</i>
        </React.Fragment>
      )
    }
  },
  {
    title: '操作',
    dataIndex: 'action',
    width: 82,
    key: 'action',
    className: 'changeTdStyle',
    render: SetAction,
  },
];

const Myquiz = () => {
    const [dataSource, setDataSource] = useState([]);
    const [total, setTotal] = useState(0);
    // const [evaStatus, setEvaStatus] = useState('0');
    const pageChange = (page) => {
      fetchData('getFormQuestionList',{ start: (page-1)*6, limit: 6 }).then((res) => {
        if (res) {
          setDataSource(res.data.list);
        }
      })
    }
    useEffect(() => {
      fetchData('getFormQuestionList',{ start: 0, limit: 6}).then((res) => {
        if (res) {
          setDataSource(res.data.list);
          setTotal(res.data.total)
        }
      })
    },[])
    useEffect(() => {
      if (dataSource && dataSource.length === 0) {
        const emptyDomDivImg = document.querySelector('.ant-empty-image');
        const emptyText = domChange(document.querySelector('.ant-empty-description'));
        const emptyDivImg = domChange(emptyDomDivImg)
        const emptyImg = domChange(emptyDomDivImg.children[0]);
        const tableBody = domChange(document.querySelector('.ant-table-placeholder'));
        emptyDivImg('height', 'auto');
        emptyDivImg('marginTop', '120px');
        emptyImg('src', tableEmptySrc, true);
        emptyImg('margin', '0 auto');
        emptyImg('width', '80px');
        emptyImg('height', '80px');
        emptyText('innerText', '目前没有问题记录', true);
        emptyText('fontSize', '14px');
        emptyText('color', '#999');
        tableBody('height', '400px');
      }
    })
    
    return (
      <section>
         <style>
           {
             `
              tr:nth-child(2n) {
                background: #f4f4f4;
              }
              tr:nth-child(2n+1) {
                background: white;
              }
              .status-style {
                color: #D0021B;
              }
              .myquiz-table .ant-table-thead>tr>th {
                padding: 8px 12px;
                text-align: left;
                background: #f4f4f4;
                // font-size: 12px;
                color: #666;
                border-right: none;
                font-weight: bold;
              }
              .myquiz-table .ant-table-thead>tr>th:last-child {
                border-right: 1px solid #e8e8e8;
              }
              .myquiz-table .ant-table-tbody .changeTdStyle {
                // font-size: 12px;
                padding: 8px 12px;
              }
              .myquiz-table .ant-pagination-item-active {
                border-color: #d0021b;
              }
         
              .myquiz-table .ant-pagination-item-active a {
                color: #d0021b;
              }
              .ant-table-thead > tr.ant-table-row-hover:not(.ant-table-expanded-row) > td, .ant-table-tbody > tr.ant-table-row-hover:not(.ant-table-expanded-row) > td, .ant-table-thead > tr:hover:not(.ant-table-expanded-row) > td, .ant-table-tbody > tr:hover:not(.ant-table-expanded-row) > td {
                background: #FFF2F2;
              }
              .ant-pagination-item:focus, .ant-pagination-item:hover {
                border-color: #D0021B;
                color: #D0021B;
              }
              .ant-pagination-item:focus a, .ant-pagination-item:hover a {
                color: #D0021B;
              }
              .ant-pagination-prev:focus .ant-pagination-item-link, .ant-pagination-next:focus .ant-pagination-item-link, .ant-pagination-prev:hover .ant-pagination-item-link, .ant-pagination-next:hover .ant-pagination-item-link {
                border-color: #D0021B;
                color: #D0021B;
              }
              .ant-pagination-disabled a, .ant-pagination-disabled:hover a, .ant-pagination-disabled:focus a, .ant-pagination-disabled .ant-pagination-item-link, .ant-pagination-disabled:hover .ant-pagination-item-link, .ant-pagination-disabled:focus .ant-pagination-item-link {
                color: rgba(0, 0, 0, 0.25);
                border-color: #d9d9d9;
                cursor: not-allowed;
              }
              
             `
           }
         </style>
         <style jsx>{`
         `}
         </style>
         <Table 
         scroll={{ x: 'max-content' }}
         rowKey={record => record.num} 
         dataSource={dataSource}
         bordered 
         className="myquiz-table" 
         // style = {{overflow:"auto"}}
         columns={columns} 
         pagination={{
           total,
           pageSize: 6,
           onChange: pageChange,
         }}
         />
      </section>
    )
}

export default Myquiz